.logistics {
    width: 100%;

    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1920px;
    padding: 80px 0 50px;
}

.logistics__title {
    font-size: 56px;
    font-style: normal;
    font-weight: 900;
    line-height: 57.5px;
    text-align: center;
    position: relative;
    z-index: 1;
}

.logistics__subtitle {
    font-size: 18px;
    font-style: normal;
    font-weight: 550;
    line-height: 26px;
    text-align: center;
    max-width: 575px;
    margin: 12px 0 0;
    position: relative;
    z-index: 1;
}

.logistics__video {
    width: 100%;
    object-fit: cover;
    margin: -139px 0 0;
    position: relative;
    z-index: 0;
}

.logistics__logos {
    margin-top: 30px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, minmax(0, max-content));
    grid-template-areas: 'item1 item2 item3 item4 item5';
    grid-template-rows: max-content;
    justify-content: space-between;
    align-items: center;
    max-width: 915px;
    position: relative;
    z-index: 1;
    flex-wrap: wrap;
    box-sizing: border-box;
}

.logistics__card-0 {
    width: 195.746px;
}

.logistics__card-1 {
    width: 122.046px;
}

.logistics__card-2 {
    width: 52.474px;
}

.logistics__card-3 {
    width: 146px;
}

.logistics__card-4 {
    width: 281px;
}

@media (max-width: 1000px) {
    .logistics {
        padding: 50px 80px;
    }

    .logistics__title {
        font-size: 44px;
        font-style: normal;
        font-weight: 750;
        line-height: 120%;
        max-width: 586px;
    }

    .logistics__video {
        margin: -80px 0 0;
    }

    .logistics__card {
        width: 100%;
        height: 100%;
    }

    .logistics__logos {
        grid-template-columns: repeat(6, 34px);
        grid-template-rows: repeat(2, minmax(0, 40px));
        row-gap: 25px;
        grid-template-areas: 'item1 item1 item1 item2 item2 item3'
            'item4 item4 item5 item5 item5 item5';
        justify-content: center;
        column-gap: 20px;
    }

    .logistics__card-0 {
        height: 28.111px;
    }

    .logistics__card-1 {
        height: 34.949px;
    }

    .logistics__card-3 {
        width: 89.894px;
    }

    .logistics__card-4 {
        width: 172.449px;
    }
}

@media (max-width: 880px) {
    .logistics {
        padding: 51px 0 33px;
        overflow: hidden;
    }


    .logistics__title {
        font-size: 24px;
        font-style: normal;
        font-weight: 900;
        line-height: 32px;

    }

    .logistics__subtitle {
        font-size: 16px;
        font-style: normal;
        font-weight: 550;
        line-height: 26px;
        margin: 10px 0 0;
    }

    .logistics__video {
        width: 150%;
        margin: -40px 0 0;
    }

    .logistics__logos {
        padding: 0 56px;
        min-height: 100px;
    }
}