.vars-element {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  border-radius: 20px;
  background: var(--article-container-color);
  box-sizing: border-box;
  padding: 25px 72px;
  margin-top: 12px;
}

.vars-element__text {
  color: var(--neutral-active);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.vars-element__list {
  display: flex;
  flex-direction: column;
}

.vars-element ul {
  list-style: initial !important;
}

.vars-element ol {
  list-style: decimal !important;
}

.vars-element__text_center{
  text-align: center !important;
}

@media (max-width: 900px) {
  .vars-element {
    gap: 12px;
    padding: 23px 20px;
  }

  .vars-element__text {
    font-size: 12px;
  }

  .vars-element__list {
    gap: 12px;
  }
}