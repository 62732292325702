.header-banner-about-us {
    width: 100%;
    padding: 57px 80px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;

    max-height: 1080px;
    overflow: hidden;
}

.header-banner-about-us__title {
    max-width: 1266px;
    width: 100%;
    font-size: 74px;
    font-style: normal;
    font-weight: 750;
    line-height: 88px;
    text-align: center;
}

.header-banner-about-us__title_green {
    color: var(--primary-text);
    font-size: 74px;
    font-style: normal;
    font-weight: 900;
    line-height: 88px;
    border-radius: 51.5px;
    background: var(--primary-text-background);
    padding: 20px 20px 5px 25px;
    width: 100%;
    height: 100%;

    max-width: 440px;
    max-height: 103px;
    text-align: center;
    margin-right: 15px;
    word-break: break-all;
}

.header-banner-about-us__subtitle {
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 350;
    line-height: 26px;
    max-width: 896px;
    font-family: "ALS Hauss";
    margin-top: 15px;
}

.header-banner-about-us__img {
    margin-top: 16px;
    height: 100%;
    width: 2669px;
    max-height: 970px;
}

@media (max-width:960px) {
    .header-banner-about-us {
        padding: 34px 16px 15px 16px;
        max-height: 500px;
    }

    .header-banner-about-us__title {
        font-size: 24px;
        font-style: normal;
        font-weight: 750;
        line-height: 30px;
        /* 125% */
    }

    .header-banner-about-us__title_green {
        font-size: 24px;
        font-style: normal;
        font-weight: 750;
        line-height: 30px;
        /* 125% */
        padding: 5px 5px 0 15px;
        margin-right: 5px;
        word-break: break-all;
    }

    .header-banner-about-us__subtitle {
        font-size: 14px;
        font-style: normal;
        font-weight: 350;
        line-height: 22px;
        margin-top: 17px;
    }

    .header-banner-about-us__img {
        width: 1226px;
        /* height: 476.356px; */
        margin-left: 170px;
    }
}

@media (max-width: 500px) {
    .header-banner-about-us__img {
        width: 626px;
        /* height: 476.356px; */
        margin-left: 170px;
    }

    .header-banner-about-us {
        padding: 34px 16px 15px 16px;
        max-height: 600px;
    }
}