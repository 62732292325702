.store {
    width: 100%;

    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1920px;
    padding: 80px 80px 61px;
}

.store__title {
    font-size: 55px;
    font-style: normal;
    font-weight: 750;
    line-height: 55px;
    text-align: center;
}

.store__subtitle {
    margin: 16px 0 0;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    max-width: 570px;
}

.store__items {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, minMax(0, 1fr));
    grid-template-rows: max-content;
    grid-auto-rows: max-content;
    column-gap: 30px;
    row-gap: 26px;
    max-width: 880px;
    margin: 50px 0 0;
}

.store__item {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 17px;
    align-items: center;
}

.store__item-img {
    width: 100%;
}

.store__item-title {
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 550;
    line-height: 116.5%; /* 18.64px */
}

.store__btn{
    margin: 76px 0 0;
    background-color: var(--primary-active);
    color: var(--primary-text);
    border-radius: 1731.843px;
    font-family: "ALS Hauss";
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 21.039px;
width: 346px;
height: 70px;
display: flex;
align-items: center;
justify-content: center;
}

@media (max-width: 920px) {
    .store__items {
        grid-template-columns: repeat(4, minMax(0, 1fr));
    }
}

@media (max-width: 880px) {
    .store {
        padding: 50px 12px 50px;
    }

    .store__title {
        font-size: 28px;
        font-style: normal;
        font-weight: 750;
        line-height: 32px;
    }

    .store__subtitle {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }
}

@media (max-width: 520px) {
    .store__items {
        grid-template-columns: repeat(3, minMax(0, 1fr));
        column-gap: 14px;
        row-gap: 32px;
        margin: 12px 0 0;
    }

    .store__btn{
        width: 100%;
    }
}