.advertising {
    width: 100%;
    padding: 110px 28px 122px 28px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.advertising__title {
    text-align: center;
    font-size: 55px;
    font-style: normal;
    font-weight: 750;
    line-height: 55px;
    /* 100% */
    text-transform: capitalize;
}

.advertising__subtitle {
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 133.333% */
    max-width: 700px;
    margin-top: 16px;
}

.advertising__procent__box {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
}

.advertising__procent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.advertising__procent-title {
    text-align: left;
    font-size: 18px;
    font-style: normal;
    font-weight: 750;
    line-height: 32.436px;
    /* 180.202% */
    user-select: none;
    pointer-events: none;
}


.progress-bar-container {
    width: 100%;
    max-width: 637.915px;
    margin: 40px auto;
    text-align: center;
}

.progress-bar-wrapper {
    margin: 18px 0;
}

.progress-bar-label {
    margin-bottom: 5px;
    text-align: left;
    font-size: 18px;
    font-style: normal;
    font-weight: 750;
    line-height: 32.436px;
    /* 180.202% */
    user-select: none;
    pointer-events: none;
}

.progress-bar {
    position: relative;
    width: 100%;
    height: 35.139px;
    background-color: var(--website-background);
    border-radius: 135.152px;
    overflow: hidden;
}

.progress-bar-fill {
    height: 100%;
    width: 0;
    background-color: red;
    transition: width 1.5s ease-in-out;
    padding: 1.35px 22px 1px 0;
    box-sizing: border-box;
    border-radius: 135.152px;
    min-height: 100%;
}

.progress-text {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-weight: bold;
    color: white;
    user-select: none;
    pointer-events: none;
}


.animate-number {
    color: #fff;
    text-align: right;
    font-size: 13.515px;
    font-style: normal;
    font-weight: 750;
    line-height: 32.436px;
    /* 240% */
    user-select: none;
    pointer-events: none;
}

@media (max-width:600px) {
    .advertising {
        padding: 50px 28px 66px 28px;
        box-sizing: border-box;
    }

    .advertising__title {
        font-size: 24px;
        font-style: normal;
        font-weight: 750;
        line-height: 32px;
        /* 133.333% */
        max-width: 294px;
    }

    .advertising__subtitle {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 171.429% */
    }

    .progress-bar-container {
        /* max-width: 293px; */
        margin: 23px 0 0 0;
    }

    .progress-bar {
        max-width: 293px;
        margin: 0 auto;
        height: 26px;
    }

    .progress-bar-label {
        font-size: 11px;
        font-style: normal;
        font-weight: 750;
        line-height: 32.436px;
        /* 294.876% */
        white-space: nowrap;
        text-align: center;
    }

    .progress-bar-wrapper {
        margin: 10px 0;
    }

    .progress-bar-fill {
        padding-right: 1px 16px 1px 0;
    }

    .animate-number {
        font-size: 10px;
        font-style: normal;
        font-weight: 750;
        line-height: 24px;
        /* 240% */
    }
}