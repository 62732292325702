.direct {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--website-secondary-background);
    padding: 110px 0 60px 0;
}

.direct__title {
    font-size: 51px;
    font-style: normal;
    text-align: center;
    font-weight: 750;
    line-height: 55px;
    text-transform: capitalize;
    max-width: 772px;
    width: 100%;
}

.direct__subtitle {
    font-size: 18px;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    max-width: 700px;
    width: 100%;
    margin-top: 16px;
}

.direct__img {
    width: 100%;
    object-fit: cover;
    max-width: 680px;
    margin-top: 40px;
    user-select: none;
    pointer-events: none;
}

@media (max-width:600px) {
    .direct {
        padding: 50px 24px;
        box-sizing: border-box;
    }

    .direct__title {
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 750;
        line-height: 32px;
        /* 133.333% */
    }

    .direct__subtitle {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 171.429% */
        margin-top: 16px;
    }
}