.tools {
    width: 100%;
    background: var(--website-secondary-background);
    padding: 110px 0 144px 0;
}

.tools__title {
    text-align: center;
    font-size: 56px;
    font-style: normal;
    font-weight: 750;
    line-height: 110%;
    /* 61.6px */
    text-transform: capitalize;
}

.tools__logos {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    max-width: 1188px;
    column-gap: 60px;
    row-gap: 50px;
    justify-content: center;
    margin: 0 auto;
    margin-top: 80px;
    padding: 0 35px;
    box-sizing: border-box;
}

.tools__logo {
    width: 100%;
    user-select: none;
    pointer-events: none;
}

@media (max-width:900px) {
    .tools {
        padding: 50px 0;
    }

    .tools__title {
        font-size: 34px;
    }

    .tools__logos {
        box-sizing: border-box;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        margin-top: 40px;
        row-gap: 45px;
        column-gap: 36px;
    }
}

@media (max-width:600px) {
    .tools__title {
        font-size: 24px;
        font-style: normal;
        font-weight: 750;
        line-height: 32px;
        /* 133.333% */
        padding: 0 8px;
        box-sizing: border-box;
    }
}