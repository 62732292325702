.marketing {
    width: 100%;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--website-secondary-background);
    padding: 80px 0 108px;
    overflow: hidden;
}

.marketing__content {
    box-sizing: border-box;
    width: 100%;

    max-width: 1920px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.marketing__title {
    font-size: 56px;
    font-style: normal;
    font-weight: 900;
    line-height: 26px;
    /* 46.429% */
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
}

.marketing__title__icon {
    position: absolute;
    left: -103px;
    top: -40px;
}

.marketing__subtitle {
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 550;
    line-height: 26px;
    /* 144.444% */
    max-width: 575px;
    margin: 27px 0 0;
    position: relative;
    z-index: 1;
}

.marketing__video {
    width: 100%;
    object-fit: cover;
    position: relative;
    z-index: 0;
}

.marketing__box {
    width: 100%;
    display: grid;
    grid-template-columns: 5fr 4fr;
    grid-template-rows: max-content;
    column-gap: 32px;
    align-items: center;
}

.marketing__img {
    width: 100%;
}

@media (max-width: 1000px) {

    .marketing__title {
        text-align: center;
        font-size: 44px;
        font-style: normal;
        font-weight: 750;
        line-height: 120%;
        max-width: 586px;
    }

    .marketing__title__icon {
        top: -25px;
        left: -30px;
        max-width: 70px;
    }
}

@media (max-width: 880px) {
    .marketing {
        overflow: hidden;
    }


    .marketing__title {
        font-size: 24px;
        font-style: normal;
        font-weight: 900;
        line-height: 32px;

    }

    .marketing__subtitle {
        font-size: 16px;
        font-style: normal;
        font-weight: 550;
        line-height: 26px;
        margin: 10px 0 0;
    }


}


@media (max-width: 780px) {

    .marketing {
        padding: 50px 0 30px;
    }

    .marketing__box {
        grid-template-columns: 1fr;
    }
}