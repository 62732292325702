.compare-features {
    width: 100%;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--website-secondary-background);
    padding: 70px 0 137px;
    overflow: hidden;
    position: relative;
}

.compare-features__content {
    box-sizing: border-box;
    width: 100%;

    max-width: 1920px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.compare-features__title {
    text-align: center;
    font-size: 56px;
    font-style: normal;
    font-weight: 750;
    line-height: 110%;
}

.compare-features__table {
    max-width: 1074px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 42px 0 0;
}

.compare-features__table-heading {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, minMax(0, 1fr));
    grid-template-rows: max-content;
    padding-bottom: 4px;
    box-sizing: border-box;
    transition: all 0.3s ease-in-out;
}

.compare-features__table-heading_sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    background-color: var(--website-background);

}

.compare-features__table-heading-fixed {
    display: none;
    /* Hidden initially */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    /* Adjust as necessary */
    z-index: 1000;
    background-color: var(--website-background);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.25);
}

.compare-features__table-heading-fixed-content {
    max-width: 1074px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 34px 0 42px;
}

.compare-features__table-heading-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    gap: 22px;
    box-sizing: border-box;
    padding: 0 24px;
}

.compare-features__table-heading-icon {
    width: 114px;
    height: 43px;
}

.compare-features__table-heading-icon_plus {
    width: 150px;
    height: 43px;
}

.compare-features__table-heading-btn {
    width: 100%;
    padding: 12px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    background-color: var(--primary-active);
    border-radius: 8px;

}

.compare-features__table-heading-btn:hover {
    background-color: var(--primary-hover);
}

.compare-features__table-heading-btn-title {
    text-align: center;
    font-family: "ALS Hauss";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    color: var(--primary-text);
    line-height: 100%;

}

.compare-features__table-heading-btn-subtitle {
    font-family: "ALS Hauss";
    font-size: 16px;
    font-style: normal;
    font-weight: 350;
    color: var(--primary-text);
    line-height: 100%;
}

.compare-features__table-heading-title-content {
    width: 100%;
    box-sizing: border-box;
    padding: 0 16px;
}

.compare-features__table-heading-title {
    font-size: 28px;
    font-style: normal;
    font-weight: 750;
    line-height: 32px;
}

.compare-features__table-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
    box-sizing: border-box;
}




.compare-features__table-item-head {
    display: grid;
    grid-template-columns: 40px max-content 24px;
    grid-template-rows: max-content;
    column-gap: 28px;
    padding: 22px 6px;
    align-items: center;
    position: relative;
    z-index: 100;
}

.compare-features__table-item-head:hover .compare-features__table-item-head-title {
    color: var(--primary-active);
}

.compare-features__table-item_even .compare-features__table-item-head {
    background-color: var(--website-secondary-background-secondary);
}

.compare-features__table-item-head-icon {
    width: 40px;
    height: 40px;
}

.compare-features__table-item-head-icon-fill {
    fill: var(--primary-active);
}


.compare-features__table-item-head-title {
    font-size: 22px;
    font-style: normal;
    font-weight: 750;
    line-height: 100%;
    transition: all 0.2s ease-in-out;

}

.compare-features__table-item_selected .compare-features__table-item-head-title {
    color: var(--primary-active);
}

.compare-features__table-item-head-arrow {
    width: 24px;
    height: 24px;
    transition: all 0.2s ease-in-out;

}

.compare-features__table-item_selected .compare-features__table-item-head-arrow {
    transform: rotate(180deg);
}

.compare-features__table-item-head-arrow-stroke {
    stroke: var(--neutral-active);
    transition: all 0.2s ease-in-out;
}

.compare-features__table-item_selected .compare-features__table-item-head-arrow-stroke {
    stroke: var(--primary-active);
}

.compare-features__table-item-inside-items {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.compare-features__table-item-inside-item {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, minMax(0, 1fr));
    grid-template-rows: max-content;
    padding: 22px 0;
    box-sizing: border-box;
}

.compare-features__table-item-inside-item_even {
    background-color: var(--website-secondary-background-secondary);
}

.compare-features__table-item-inside-item_odd {
    background-color: var(--website-secondary-background-secondary);
}

.compare-features__table-item-inside-item-cell {
    box-sizing: border-box;
    width: 100%;
    padding: 0 24px;
    display: flex;
    flex-direction: row;
}

.compare-features__table-item-inside-item-title {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.compare-features__table-item-inside-item-answer-cell {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.compare-features__table-item-inside-item-answer-text {
    font-family: "ALS Hauss";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    opacity: 0.7;
}

.compare-features__table-item-inside-item-answer-tick {
    width: 24px;
    height: 24px;
}

.compare-features__table-item-inside-item-answer-tick-stroke {
    stroke: var(--primary-active);
}

.compare-features__table-item-inside-item-answer-false-tick {
    width: 40px;
    height: 2px;
}

.compare-features__table-item-inside-item-answer-false-tick-stroke {
    stroke: var(--neutral-active);
}

@media (max-width: 1100px) {
    .compare-features__title {
        font-size: 34px;
    }
}

@media (max-width: 880px) {
    .compare-features__title {
        font-size: 28px;
        font-style: normal;
        font-weight: 750;
        line-height: 32px;
    }

    .compare-features__table-item-inside-item {
        grid-template-columns: 1fr repeat(2, 108px);
    }

    .compare-features__table-heading {
        grid-template-columns: 1fr repeat(2, 108px);
    }

    .compare-features__table-heading-content {
        padding: 0 10px;
        gap: 14px;
    }

    .compare-features__table-heading-icon {
        width: 65px;
        height: 24px;
    }

    .compare-features__table-heading-icon_plus {
        width: 89.535px;
        height: 25.621px;
    }

    .compare-features__table-heading-btn {
        gap: 4px;
    }

    .compare-features__table-heading-btn-title {
        font-size: 10.268px;
        font-style: normal;
        font-weight: 700;
    }

    .compare-features__table-heading-btn-subtitle {
        font-size: 10.268px;
        font-style: normal;
        font-weight: 350;
    }

    .compare-features__table-heading-fixed-content {
        padding: 17px 0 21px;
    }

    .compare-features__table-heading-title {
        font-size: 16px;
        font-style: normal;
        font-weight: 750;
        line-height: 22px;
    }

    .compare-features__table-item-head {
        column-gap: 8px;
        grid-template-columns: 40px auto 24px;
    }

    .compare-features__table-item-head-title {
        font-size: 16px;
        font-style: normal;
        font-weight: 750;
        line-height: 20px;
        max-width: 90%;
        text-align: left;
    }

    .compare-features__table-item-inside-item-title {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }
}