.language-popup {
    background: var(--lang-box-bg);
    width: 100vw;
    height: 100vh;
    position: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    visibility: hidden;
    opacity: 0;
    z-index: 10;
    transition: visibility 0.5s ease-in-out, opacity 0.4s ease-in-out;
}

.language-popup_opened {
    visibility: visible;
    opacity: 1;
    transition: visibility 0.5s ease-in-out, opacity 0.4s ease-in-out;
}

.language-popup__box {
    background: #fff;
    border-radius: 17px;
    padding: 28px 95px 69px 80px;
    position: relative;
    width: 100%;
    max-width: 922px;
    box-sizing: border-box;
}

.language-popup__box__title {
    color: #000;
    text-align: center;
    font-size: 27px;
    font-style: normal;
    font-weight: 900;
    line-height: 50px;
    /* 185.185% */
}

.language-popup__close {
    position: absolute;
    top: 28px;
    right: 35px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.language-popup__box__languages {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    column-gap: 25px;
    row-gap: 12px;
}

.language-popup__box__language {
    border-radius: 62.481px;
    background: #F5F5F5;
    width: 100%;
    padding: 7px 8px;
    display: flex;
    align-items: center;
    gap: 7px;
    justify-content: flex-start;

    color: #000;
    font-size: 16.171px;
    font-style: normal;
    font-weight: 550;
    line-height: 57.754px;
    /* 357.143% */
    max-height: 34px;
    box-sizing: border-box;
    cursor: pointer;
    border: 2px solid #F5F5F5;
}

.language-popup__box__language:hover {
    background: var(--lang-box-hover);
    transition: all ease-in-out 0.2s;
    border: 2px solid var(--lang-box-hover);
}

.language-popup__box__language_active {
    border: 2px solid var(--primary-active);
}

.language-popup__box__language_active:hover {
    background: var(--lang-box-hover);
    transition: all ease-in-out 0.2s;
    border: 2px solid var(--primary-active);
}

@media (max-width:1000px) {
    .language-popup__box__languages {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

@media (max-width:800px) {
    .language-popup {
        /* padding: 0 25px; */
    }

    .language-popup__close {
        top: 5px;
        right: 5px;
    }

    .language-popup__box {
        max-height: 90vh;
        overflow: scroll;
        padding: 42px 26px 71px 26px;
    }

    .language-popup__box__title {
        font-size: 18px;
        font-style: normal;
        font-weight: 900;
        line-height: 20px;
        /* 111.111% */
        text-align: left;
    }

    .language-popup__box__languages {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        margin-top: 17px;
        column-gap: 16px;
        row-gap: 9px;
    }

    .language-popup__box__language {
        font-size: 12px;
        font-style: normal;
        font-weight: 550;
        line-height: 57.754px;
        /* 481.283% */
    }
}