.partners-pros {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 120px 32px;
}

.partners-pros__title {
  max-width: 805px;
  text-align: center;
  font-size: 56px;
  font-style: normal;
  font-weight: 800;
  line-height: 107%;
}

.partners__btn {
  border-radius: 1731.843px;
  background: var(--primary-active);
  display: flex;
  width: 346px;
  height: 70px;
  padding: 0px 20.782px;
  justify-content: center;
  align-items: center;
  gap: 6.927px;
  color: #FFF;
  text-align: center;
  font-family: 'ALS Hauss', sans-serif;
  
  font-size: 18px;
  font-style: normal;
  font-weight: 550;
  line-height: 21.039px;
  margin-top: 60px;
  /* 116.882% */
}

.partners-pros__list {
  display: grid;
  grid-template-columns: repeat(4, 247px);
  grid-template-rows: 247px;
  grid-auto-rows: 247px;
  gap: 25px;
  margin-top: 60px;
}

.partners-pros__item {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  border-radius: 25px;
  border: 6px solid;
  box-sizing: border-box;
  padding: 22px 0 0 22px;
}

.partners-pros__item:first-child {
  border-color: var(--partners-first-card-border);
  background: var(--partners-first-card-bg);
}

.partners-pros__item:nth-child(2) {
  border-color: var(--partners-second-card-border);
  background: var(--partners-second-card-bg);
}

.partners-pros__item:nth-child(3) {
  border-color: var(--partners-third-card-border);
  background: var(--partners-third-card-bg);
}

.partners-pros__item:last-child {
  border-color: var(--partners-fours-card-border);
  background: var(--partners-fours-card-bg);
}

.partners-pros__text {
  color: var(--partners-text-color);
  font-size: 18px;
  font-style: normal;
  font-weight: 750;
  line-height: 122%;
}

.partners-pros__icon {
  object-fit: contain;
  user-select: none;
  pointer-events: none;
}

.partners-pros__item:first-child .partners-pros__icon {
  width: 175px;
  margin-bottom: 16px;
}

.partners-pros__item:nth-child(2) .partners-pros__icon {
  width: 175px;
}

.partners-pros__item:nth-child(3) .partners-pros__icon {
  width: 162px;
}

.partners-pros__item:last-child .partners-pros__icon {
  width: 191px;
  margin-bottom: 24px;
}

@media (max-width: 1120px) {
  .partners-pros__list {
    grid-template-columns: repeat(2, 247px);
  }
}

@media (max-width: 780px) {
  .partners-pros {
    padding: 60px 20px;
  }

  .partners-pros__title {
    max-width: 348px;
    font-size: 28px;
    line-height: 114%;
  }

  .partners-pros__list {
    grid-template-columns: repeat(2, 165px);
    grid-template-rows: 165px;
    grid-auto-rows: 165px;
    gap: 16px;
    margin-top: 28px;
  }

  .partners-pros__item {
    border-radius: 16px;
    border: 4px solid;
    padding: 14px 0 0 14px;
  }

  .partners-pros__text {
    font-size: 12px;
  }

  .partners-pros__item:first-child .partners-pros__icon {
    width: 116px;
    margin-bottom: 12px;
  }

  .partners-pros__item:nth-child(2) .partners-pros__icon {
    width: 115px;
  }

  .partners-pros__item:nth-child(3) .partners-pros__icon {
    width: 106px;
  }

  .partners-pros__item:last-child .partners-pros__icon {
    width: 128px;
    margin-bottom: 16px;
  }
}