.channel {
    width: 100%;
    background: var(--website-secondary-background);
    padding: 110px 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 880px;
    box-sizing: border-box;
}

.channel__title {
    font-size: 56px;
    font-style: normal;
    font-weight: 750;
    line-height: 55px;
    text-transform: capitalize;
    width: 100%;
    max-width: 840px;
    text-align: center;
}

.channel__subtitle {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    max-width: 800px;
    text-align: center;
    margin-top: 16px;
}

.channel__img-box {
    display: flex;
    justify-content: center;
    height: 539px;
    width: 100%;
    position: relative;
    max-width: 1300px;
}

.channel__img {
    position: absolute;
    width: 100%;
    top: 112px;
    max-width: 628px;
    margin-top: -35%;
    user-select: none;
    pointer-events: none;
}

.channel__img-0 {
    z-index: 4;
}

.channel__img-1 {
    z-index: 3;
}

.channel__img-2 {
    z-index: 2;
}

.channel__img-3 {
    z-index: 1;
}

@media (max-width:1300px) {
    .channel__img {
        position: absolute;
        width: 50%;
        top: 112px;
        max-width: 558px;
        margin-top: -35%;
    }
}

@media (max-width:1000px) {
    .channel {
        height: 700px;
    }

    .channel__img-box {
        width: 90%;
    }
}

@media (max-width:900px) {
    .channel {
        height: 900px;
        padding: 110px 24px;
    }

    .channel__img-box {
        justify-content: center;
    }

    .channel__img {
        width: 60%;
        bottom: 65%;
        top: unset;
    }
}

@media (max-width:600px) {
    .channel {
        padding: 50px 24px 0 24px;
        height: 700px;
    }

    .channel__title {
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 750;
        line-height: 32px;
        /* 133.333% */
    }

    .channel__subtitle {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 171.429% */
        margin-top: 16px;
    }

    .channel__img {
        width: 80%;
        bottom: 70%;
    }
}

@media (max-width:450px) {
    .channel {
        height: 600px;
    }

    .channel__img {
        bottom: 70%;
    }
}

@media (max-width:400px) {
    .channel__img {
        /* top: 0%; */
    }
}