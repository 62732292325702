.analytics {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 110px 0 138px 0;
}

.analytics__title {
    text-align: center;
    font-size: 56px;
    font-style: normal;
    font-weight: 750;
    line-height: 55px;
    /* 98.214% */
    max-width: 700px;
}

.analytics__subtitle {
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 133.333% */
    max-width: 652px;
    margin-top: 16px;
}

.analytics__images {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    max-width: 755px;
    width: 100%;
    column-gap: 23px;
    row-gap: 21px;
    margin-top: 67px;
    user-select: none;
    pointer-events: none;
}

.analytics__img-box {
    width: 100%;
    min-height: 185.27px;
    min-width: 366px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 50px;
    box-sizing: border-box;
}

.analytics__img {
    width: 100%;
    object-fit: cover;
}

@media (max-width:600px) {
    .analytics {
        padding: 50px 24px;
        box-sizing: border-box;
    }

    .analytics__title {
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 750;
        line-height: 32px;
        /* 133.333% */
        max-width: 266px;
    }

    .analytics__subtitle {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 171.429% */
        margin-top: 16px;
        max-width: 334px;
    }

    .analytics__mages {
        margin-top: 26px;
        row-gap: 10px;
        column-gap: 11px;
    }
    .analytics__img-box{
        min-width: unset;
        min-height: unset;
        padding: unset;
    }
}