.integration {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 110px 0 60px 0;
    box-sizing: border-box;
    height: 1080px;
}

.integration__title {
    text-align: center;
    font-size: 56px;
    font-style: normal;
    font-weight: 750;
    line-height: 55px;
    /* 98.214% */
    text-transform: capitalize;
    max-width: 772px;
    width: 100%;
}

.integration__subtitle {
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 133.333% */
    max-width: 700px;
    width: 100%;
    margin-top: 16px;
}


.integration__img-box {
    width: 100%;
    max-width: 1440px;
    display: flex;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    margin-top: -6.92%;
    will-change: opacity, transform;
    height: 793px;
}

.integration__icon-item:first-child {
    width: 8.33%;
    width: 160px;
    /* box-shadow: 0px 4.085px 13.276px 0px var(--integration-icons-shadow); */
    top: 45.0%;
    left: 15.97%;
}

.integration__icon-item:nth-child(2) {
    width: 7.22%;
    width: 315px;
    /* box-shadow: 0px 3.552px 11.544px 0px var(--integration-icons-shadow); */
    top: 64.5%;
    left: 15.7%;
}

.integration__icon-item:nth-child(3) {
    width: 10.56%;
    width: 210px;
    /* box-shadow: 0px 5.196px 16.889px var(--integration-icons-shadow); */
    top: 43.58%;
    right: 14.2%;

}

.integration__icon-item:last-child {
    width: 5.83%;
    width: 395px;
    /* box-shadow: 0px 2.862px 9.303px 0px var(--integration-icons-shadow); */
    top: 55.3%;
    right: 15.4%;
}

.integration__icon {
    object-fit: contain;
    user-select: none;
    pointer-events: none;
}

.integration__icon-item:first-child .integration__icon {
    width: 79.17%;
    width: 190.476px;
}

.integration__icon-item:nth-child(2) .integration__icon {
    width: 79.81%;
    width: 315.368px;
}

.integration__icon-item:nth-child(3) .integration__icon {
    width: 56.58%;
    width: 210.461px;
}

.integration__icon-item:last-child .integration__icon {
    width: 79.76%;
    width: 395.325px;
}


.integration__screens-list {
    width: 915px;
    height: 750px;
    display: flex;
    position: absolute;
    top: 7.04%;
    z-index: 1;
    justify-content: center;
}

.integration__screen-item {
    width: 311px;
    display: flex;
    position: relative;
    will-change: opacity, transform;
}

.integration__screen-item:first-child {
    transform: rotate(-7.169deg);
    top: 5.47%;
    margin-right: -9.0%;
    z-index: 1;
}

.integration__screen-item:nth-child(2) {
    transform: rotate(2.243deg);
    z-index: 2;
}

.integration__screen-item:last-child {
    transform: rotate(9.203deg);
    top: 3.7%;
    margin-left: -7%;
    z-index: 3;
}

.integration__screen-img {
    width: 100%;
    object-fit: contain;
    user-select: none;
    pointer-events: none;
}

.integration__icons-list {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 3;
}

.integration__icon-item {
    box-shadow: none;
    aspect-ratio: 1/1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    /* background: var(--integration-icons-bg); */
    border-radius: 26%;
    will-change: opacity, transform;
}

@media (max-width:1200px) {
    .integration__icon-item:first-child {
        left: 6%;
    }

    .integration__icon-item:nth-child(2) {
        left: 6%;
    }

    .integration__icon-item:nth-child(3) {
        right: 6%;
    }

    .integration__icon-item:last-child {
        right: 6%;
    }
}

@media (max-width:1000px) {
    .integration__img-box {
        height: 500px;
    }

    .integration__screens-list {
        height: 100%;
        margin-top: 2%;
    }

    .integration__screen-item {
        width: 213px;
    }

    .integration__icon-item:first-child {
        left: 0%;

    }

    .integration__icon-item:first-child .integration__icon {
        width: 68.956px;
    }

    .integration__icon-item:nth-child(2) {
        left: 0%;
    }

    .integration__icon-item:nth-child(2) .integration__icon {
        width: 162px;
    }

    .integration__icon-item:nth-child(3) {
        right: 0%;
    }

    .integration__icon-item:nth-child(3) .integration__icon {
        width: 126px;
    }

    .integration__icon-item:last-child {
        right: 0%;
    }

    .integration__icon-item:last-child .integration__icon {
        width: 193px;
    }
}

@media (max-width:600px) {
    .integration {
        padding: 40px 24px;
        box-sizing: border-box;
        height: 600px;
        justify-content: flex-start;
    }

    .integration__title {
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 750;
        line-height: 32px;
        /* 133.333% */
    }

    .integration__subtitle {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 171.429% */
        margin-top: 16px;
    }

    .integration__screens-list {
        height: 100%;
    }

    .integration__img-box {
        height: 300px;
    }

    .integration__screen-item {
        width: 113px;
    }

    .integration__icon-item:first-child .integration__icon {
        width: 68.956px;
    }

    .integration__icon-item:nth-child(2) .integration__icon {
        width: 112px;
    }

    .integration__icon-item:nth-child(3) .integration__icon {
        width: 76px;
    }

    .integration__icon-item:last-child .integration__icon {
        width: 143px;
    }

    .integration__screen-item:first-child {
        margin-right: -3%;
    }

    .integration__screen-item:last-child {
        margin-left: -2.5%;
    }

    .integration__icon-item:first-child {
        left: 8%;
        top: 38%;
    }

    .integration__icon-item:nth-child(2) {
        top: 36%;
        left: -1%;
    }

    .integration__icon-item:nth-child(3) {
        top: 30%;
        right: 3%;
    }

    .integration__icon-item:last-child {
        top: 22%;
        right: -7%;
    }
}


@media (max-width:500px) {
    .integration__icon-item:first-child {
        left: -10%;
        top: 38%;
    }

    .integration__icon-item:nth-child(2) {
        top: 36%;
        left: -15%;
    }

    .integration__icon-item:nth-child(3) {
        top: 30%;
        right: -15%;
    }

    .integration__icon-item:last-child {
        top: 22%;
        right: -27%;
    }
}

@media (max-width:400px) {
    .integration__icon-item:first-child {
        left: -18%;
        top: 38%;
    }

    .integration__icon-item:nth-child(2) {
        top: 36%;
        left: -28%;
    }

    .integration__icon-item:nth-child(3) {
        top: 30%;
        right: -24%;
    }

    .integration__icon-item:last-child {
        top: 22%;
        right: -41%;
    }
}