.main-nav {
  position: fixed;
  top: 108px;
  padding-left: 26px;
  z-index: 9;
}

.main-nav__links-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.main-nav__link-item {
  display: flex;
  align-items: center;
  position: relative;
}

.main-nav__link {
  text-align: center;
  text-align: center;
  font-family: "ALS Hauss";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  /* 142.857% */

  white-space: nowrap;
  transition: all 0.2s ease-in-out;
}

.main-nav__link::before {
  content: "";
  width: 18px;
  height: 2px;
  position: absolute;
  top: 8px;
  left: -26px;
  background: var(--primary-text-background);
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

.main-nav__link_active {
  /* color: var(--primary-text-background); */
}

.main-nav__link_active::before {
  opacity: 1;
}

.main-nav__link_collapsed::before {
  opacity: 1;
}

.main-nav__link-btn {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "ALS Hauss";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 142%;
  color: var(--neutral-active);
  border-radius: 26px;
  border: 2px solid var(--neutral-active);
  box-sizing: border-box;
  padding: 14px 27px 14px 26px;
  margin-top: 22px;
  max-height: 41px;
}

.main-nav__link-text {
  text-align: center;
  font-family: "ALS Hauss";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
}

@media (max-width: 1200px) {
  .main-nav {
    display: none;
  }
}