.custom-input {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
  position: relative;
}

.custom-input__input-heading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.custom-input__label {
  color: var(--primary-text);

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-input__input {
  width: 100%;
  height: 40px;
  color: var(--primary-text);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 171%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 34px;
  border: 1px solid var(--partners-input-border);
  background: var(--partners-input-bg);
  box-shadow: 0px 1px 2px 0px var(--partners-input-shadow);
  box-sizing: border-box;
  padding: 8px 12px;
  outline: transparent;
}

.custom-input__input::placeholder {
  color: var(--primary-text);
  opacity: 0.8;
}

.custom-input__input_full {
  height: 89px;
}

.custom-input__input_full::placeholder {
  color: rgba(0, 0, 0, 0.50);
  font-family: "Inter", serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 171.429% */
}

@media (max-width: 780px) {
  .custom-input__label {
    font-size: 14px;
  }

  .custom-input__input {
    font-size: 12px;
  }
}