.business {
    display: flex;
    flex-direction: row;
    height: 920px;
    width: 100%;
    padding: 0 0 0 193px;
    align-items: center;

    display: grid;
    grid-template-columns: 562px 1209px;
}

.business__text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;


}

.business__title {
    font-size: 56px;
    font-style: normal;
    font-weight: 750;
    line-height: 60px;
    /* 107.143% */
    max-width: 562px;
}

.business__subtitle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 16px;
    gap: 4px;
}

.business__subtitle__box {
    display: flex;
    align-items: center;
    gap: 6px;
}

.business__subtitle__box__title {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 133.333% */
}

.business__btn {
    border-radius: 2000px;
    background: var(--primary-active);
    display: flex;
    height: 67px;
    padding: 0px 24px;
    justify-content: center;
    align-items: center;

    color: #FFF;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 900;
    line-height: 24.296px;
    /* 121.481% */
    margin-top: 16px;
}

.business__img {
    max-width: 1209.122px;
    width: 100%;
    height: 920px;
    margin-left: -130px;
    user-select: none;
    pointer-events: none;
}

@media (max-width:1300px) {
    .business {
        padding: 0 0 0 80px;
    }
}

@media (max-width:1100px) {
    .business {
        padding: 0 0 0 24px;
    }
}

@media (max-width:960px) {
    .business {
        padding: 0 0 0 0px;
        grid-template-columns: 1fr;
        padding-top: 50px;
        align-items: flex-start;
        grid-template-rows: 230px 331.789px;
        height: 750px;
    }

    .business__text {
        justify-content: center;
        align-items: center;
    }

    .business__title {
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 750;
        line-height: 32px;
        /* 133.333% */
    }

    .business__subtitle {
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 171.429% */
        align-items: center;
    }

    .business__btn {
        text-align: center;
        font-size: 17.318px;
        font-style: normal;
        font-weight: 900;
        line-height: 21.039px;
        /* 121.481% */
        height: 58px;
    }

    .business__img {
        width: 650px;
        height: 500px;
        flex-shrink: 0;
        margin: 0 auto;
    }
}

@media (max-width:600px) {
    .business {
        height: 661px;
        padding: 58px 16px 0 16px;
        box-sizing: border-box;
    }

    .business__img {
        width: 423.624px;
        height: 331.789px;
        flex-shrink: 0;
        margin-top: 37px;
        margin-left: -20px;
    }

    .business__text {
        max-width: calc(100vw - 32px);
    }
}