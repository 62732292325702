.partners-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  padding: 132px 32px 115px;
}

.partners-form__title {
  max-width: 896px;
  text-align: center;
  font-size: 129px;
  font-style: normal;
  font-weight: 900;
  line-height: 100%;
  text-transform: uppercase;
  background: linear-gradient(180deg,
      var(--primary-text) 0%,
      rgba(255, 255, 255, 0) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  z-index: 2;
}

.partners-form__subtitle {
  color: var(--primary-text);
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  padding-top: 28px;
  position: relative;
  z-index: 2;
}

.partners-form__form {
  width: 100%;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 64px;
  border: 6px solid var(--partners-form-border);
  background: var(--partners-form-bg);
  backdrop-filter: blur(42.75px);
  box-sizing: border-box;
  padding: 50px 50px 65px;
  margin-top: 98px;
  position: relative;
  z-index: 2;
}

.partners-form__container {
  width: 100%;
  max-width: 526px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  position: relative;
}

.partners-form__form-title {
  color: var(--primary-text);
  text-align: center;
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
  line-height: 128%;
}

.partners-form__inputs-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.partners-form__submit-btn {
  width: 100%;
  height: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primary-text);
  text-align: center;
  font-family: "ALS Hauss";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 117%;
  border-radius: 1731px;
  background-color: var(--partners-color-accent);
  box-sizing: border-box;
  padding: 0px 20px;
}

.partners-form__submit-btn_submit {
  transition: 0.5s background-color ease-in-out;
  background-color: var(--footer-button-second);
}

.partners-form__bg {
  width: 100%;
  height: 100%;
  min-width: 1920px;
  object-fit: cover;
  user-select: none;
  pointer-events: none;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 0;
}

.partners-form__stars {
  width: 530px;
  height: 598px;
  object-fit: contain;
  user-select: none;
  pointer-events: none;
  position: absolute;
  pointer-events: none;
  z-index: 1;
}

.partners-form__stars_type_top-left {
  top: -23px;
  left: -330px;
}

.partners-form__stars_type_bottom-left {
  top: 423px;
  left: 18px;
}

.partners-form__stars_type_top-right {
  top: 68px;
  right: -280px;
}

.partners-form__stars_type_bottom-right {
  top: 466px;
  right: 0;
}

.partners-form__success-text {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 142%;
  margin-top: -20px;
  position: absolute;
  bottom: -30px;
  text-align: center;
}

@media (max-width: 1000px) {
  .partners-form__title {
    font-size: 100px;
  }

  .partners-form__subtitle {
    font-size: 32px;
  }
}

@media (max-width: 780px) {
  .partners-form {
    padding: 65px 20px 35px;
  }

  .partners-form__title {
    font-size: 55px;
  }

  .partners-form__subtitle {
    font-size: 22px;
    padding-top: 12px;
  }

  .partners-form__form {
    border-radius: 20px;
    border: 3px solid var(--partners-form-border);
    padding: 34px 28px 48px;
    margin-top: 32px;
  }

  .partners-form__container {
    gap: 30px;
  }

  .partners-form__form-title {
    font-size: 20px;
  }

  .partners-form__bg {
    height: 100%;
    top: unset;
  }

  .partners-form__success-text {
    font-size: 10px;
    bottom: -35px;
  }
}