.main-partners {
    background: var(--website-secondary-background);

    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1920px;
    padding: 155px 80px 154px;
}

.main-partners__title {
    text-align: center;
    font-size: 56px;
    font-style: normal;
    font-weight: 750;
    line-height: 60px;
    max-width: 1044px;
}

.main-partners__subtitle {
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 550;
    line-height: 45px;
    /* 160.714% */
    margin-top: 19px;
    max-width: 1015px;
}

.main-partners__cards {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: repeat(4, max-content);
    column-gap: 30px;
    row-gap: 30px;
    margin-top: 52px;
    max-width: 1110px;
}

.main-partmers__card {
    width: 100%
}

@media (max-width: 1200px) {
    .main-partners {
        padding: 60px 80px 73px;
    }

    .main-partners__cards {
        column-gap: 14px;
        row-gap: 14px;
    }
}

@media (max-width: 800px) {
    .main-partners {
        padding: 40px 21px 68px 21px;
    }

    .main-partners__title {
        font-size: 28px;
        font-style: normal;
        font-weight: 750;
        line-height: 35px;
        /* 125% */
    }

    .main-partners__subtitle {
        margin-top: 15px;
        font-size: 15px;
        font-style: normal;
        font-weight: 550;
        line-height: 22.337px;
        /* 148.911% */
    }

    .main-partners__cards {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}