.footer {
  background-color: var(--website-footer-background);
  width: 100%;
  padding: 92px 80px 42px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  z-index: 10;
}

.footer__box {
  width: 100%;
  max-width: 1335px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer__title {
  color: var(--primary-text);
  font-size: 56px;
  text-align: center;
  font-style: normal;
  font-weight: 900;
  line-height: 116.5%;
}

.footer__title_type_green {
  color: var(--primary-active);
}

.footer__subtitle {
  color: var(--primary-text);
  text-align: center;
  max-width: 592px;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 116.5%;
  margin-top: 38px;
}

.footer__button {
  width: 100%;
  max-width: 568px;
  height: 138px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 95px;
  position: relative;

  border-radius: 320px;
  background: linear-gradient(180deg,
      var(--footer-blur-first-color) 0%,
      var(--footer-blur-second-color) 27.6%,
      var(--footer-blur-third-color) 100%);

  color: var(--primary-text);
  text-align: center;
  font-family: 'ALS Hauss';
  font-size: 33px;
  font-style: normal;
  font-weight: 400;
  line-height: 116.5%;
  text-transform: capitalize;

  box-sizing: border-box;
  padding-right: 63px;
  margin-top: 55px;
  transition: all 2ms ease-in-out;
}

.footer__button:hover {
  background: linear-gradient(180deg,
      var(--footer-button-first) 0%,
      var(--footer-button-second) 27.6%,
      var(--footer-button-third) 100%);
}

.button__smile_laptop {
  display: block;
}

.button__smile_mobile {
  display: none;
}

.button__blur {
  position: absolute;
  left: auto;
  top: 120px;
  pointer-events: none;
  user-select: none;
}

.button__blur_mobile {
  display: none;
}

.button__blur_laptop {
  display: block;
}

.footer__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 48px;
  border-top: 1px solid var(--footer-border);
  border-bottom: 3px solid var(--primary-text);
  box-sizing: border-box;
  padding: 35px 0 16px;
  margin-top: 82px;
}

.footer__links-block {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 82px;
}

.footer__nav-links-list {
  width: 100%;
  max-width: 800px;
  display: grid;
  grid-template-columns: repeat(2, minmax(min-content, max-content));
  grid-template-rows: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  grid-auto-flow: column;
  justify-content: space-between;
  gap: 35px;
  box-sizing: border-box;
  padding-left: 2px;
}

.footer__nav-link {
  text-align: left;
  color: var(--primary-text);
  font-size: 21px;
  font-style: normal;
  font-weight: 750;
  line-height: 116.5%;
  /* white-space: nowrap; */
}

.footer__contacts{
  display: flex;
  flex-direction: column;

}

.footer__contacts-list {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 24px;
  box-sizing: border-box;
  padding-top: 12px;
  flex-direction: column;
  align-items: end;
}

.footer__contacts-list__socials {
  display: flex;
  gap: 12px;
  margin-top: 18px;
  justify-content: flex-end;
}

.footer__contact-item {
  display: flex;
}

.footer__contact-link {
  color: var(--primary-text);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 116.5%;
}

.footer__logos-list {
  display: flex;
  align-items: center;
  gap: 12px;
}

.footer__logo-item {
  display: flex;
}

.footer__logo {
  width: 100%;
  max-width: 64px;
  object-fit: contain;
  pointer-events: none;
  user-select: none;
}

.footer__logo_type_master {
  max-width: 44px;
}

.footer__logo_type_visa {
  max-width: 60px;
}

.footer__logo_type_magnati {
  max-width: 105px;
}

.footer__rights {
  color: var(--primary-text);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 116.5%;
  align-self: flex-start;
  margin-top: 16px;
}

@media (max-width: 1000px) {
  .footer {
    padding: 38px 26px;
  }

  .footer__title {
    font-size: 22px;
    font-weight: 750;
  }

  .footer__subtitle {
    font-size: 14px;
    margin-top: 14px;
  }

  .footer__button {
    max-width: 302px;
    height: 73px;
    gap: 54px;
    font-size: 16px;
    font-weight: 500;
    padding-right: 33px;
    margin-top: 14px;
  }

  .footer__button:hover {
    background: linear-gradient(180deg,
        var(--footer-blur-first-color) 0%,
        var(--footer-blur-second-color) 27.6%,
        var(--footer-blur-third-color) 100%);
  }

  .button__smile_laptop {
    display: none;
  }

  .button__smile_mobile {
    display: block;
  }

  .button__blur {
    top: 66px;
  }

  .button__blur_mobile {
    display: block;
  }

  .button__blur_laptop {
    display: none;
  }

  .footer__content {
    gap: 32px;
    padding: 26px 0 16px;
    margin-top: 56px;
  }

  .footer__links-block {
    gap: 60px;
  }

  .footer__nav-links-list {
    gap: 25px;
  }

  .footer__contacts-list {
    gap: 12px;
    padding-left: 4px;
  }

  .footer__contact-link {
    opacity: 0.8;
  }

  .footer__logo {
    max-width: 60px;
  }

  .footer__logo_type_master {
    max-width: 41px;
  }

  .footer__logo_type_visa {
    max-width: 55px;
  }

  .footer__logo_type_magnati {
    max-width: 98px;
  }
}

@media (max-width: 800px) {
  .footer__links-block {
    flex-wrap: wrap;
  }

  .footer__nav-links-list {
    padding: 0 10px;
  }

  .footer__contacts{
    align-items: flex-start;
  }

  .footer__contacts-list {
    max-width: 80%;
    align-items: flex-start
  }

  .footer__logos-list {
    width: calc(100% + 22px);
    justify-content: center;
    margin-left: -11px;
  }
}

@media (max-width: 580px) {
  .footer__content {
    border-top: none;
    padding: 0 0 16px;
  }

  .footer__nav-links-list {
    grid-template-columns: 1fr;
    grid-auto-flow: row;
  }
}